<script>
export default {
  props: ['player', 'placeholder'],
  name: "PlayerTableCellLogs"
}
</script>

<template>
  <c-content v-if="player.cftools_id">
    <router-link target="_blank" :to="{name: 'profile', params: {cftools_id: player.cftools_id}}" v-if="player.display_name">
      <img
          :src="player.avatar"
          alt="profile picture"
          class="rounded-circle avatar-xs"
      />
      {{ player.display_name }}
    </router-link>
    <router-link target="_blank" :to="{name: 'profile', params: {cftools_id: player.cftools_id}}" v-else>
      {{ player.name }}
    </router-link>
  </c-content>
  <c-content v-else>
    <template v-if="placeholder === 'system'">
      <span class="badge badge-primary font-size-12">
        <i class="fal fa-tools" /> System
      </span>
    </template>
    <template v-else>
      <!-- Empty -->
    </template>
  </c-content>
</template>